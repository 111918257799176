<template>
  <div class="flex h-full flex-col">
    <div class="flex flex-col items-center">
      <NuxtImg
        src="/c.png"
        class="mb-8 size-10"
      />
      <h1 class="mb-4">
        Inloggen
      </h1>
      <p class="text-gray-500">
        Voer uw e-mailadres en wachtwoord in.
      </p>
      <hr class="my-8">
    </div>

    <div class="grow px-6">
      <SofieAuthLoginForm
        @success="notify({text: 'U bent nu ingelogd', type: 'success'})"
        @error="notify({text: 'Er is een fout opgetreden bij het inloggen', type: 'error'})"
      >
        <template #after-submit>
          <NuxtLink
            class="mx-auto mt-auto text-brand-600"
            :to="{name: 'auth-request-password'}"
          >
            Wachtwoord vergeten?
          </NuxtLink>
        </template>
      </SofieAuthLoginForm>
    </div>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  auth: {
    unauthenticatedOnly: true,
  },
  layout: 'auth',
});

useSeoMeta({
  title: 'Inloggen',
});
</script>
